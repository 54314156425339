import { Injectable } from '@angular/core';
import { HttpRequest, HttpResponse } from '@angular/common/http';
import { LocalStorageService } from './local-storage.service';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class HttpCacheService {

  urls = [
    `${environment.apiUrl}lookup/companies/`,
    `${environment.apiUrl}lookup/regioncities/`,
    `${environment.apiUrl}lookup/bandlevels/`,
    `${environment.apiUrl}lookup/claimstatuses/`,
    `${environment.apiUrl}lookup/servicegroups/`,
    `${environment.apiUrl}lookup/transactiontypes/`
  ];

  constructor(
    public localStorageService: LocalStorageService
  ) { }

  public get(req: HttpRequest<any>): HttpResponse<any> | undefined {

    const url = req.url;

    const cached = this.localStorageService.getItem(url);
    if (!cached || req.method !== 'GET') {
      return undefined;
    }

    const response: HttpResponse<any> = new HttpResponse(JSON.parse(cached));

    return response;

  }

  public put(req: HttpRequest<any>, response: HttpResponse<any>): void {

    if (req.method !== 'GET' || this.urls.findIndex((value) => req.url.toLowerCase() === value.toLowerCase()) === -1) {
      return undefined;
    }

    const url = req.url;

    this.localStorageService.setItem(url, JSON.stringify(response));


  }

}
