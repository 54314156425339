import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/core/services/auth.service';
import { Router } from '@angular/router';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  constructor(
    private authService: AuthService,
    private router: Router,
    private localStorageService: LocalStorageService
  ) {}

  status: string;
  name: string;
  image: string;
  alternateDisplayPhoto = '';
  hasProfilePhoto = true;
  firstName = '';
  lastName = '';

  ngOnInit() {
    this.status = 'Online'; // Placeholder
    this.name = this.GetName();
  }

  GetName(): string {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));

    this.firstName = currentUser.firstname;
    this.lastName = currentUser.lastname;
    this.image = currentUser.imageUrl;
    this.alternateDisplayPhoto = this.firstName.substr(0, 1).toUpperCase() + this.lastName.substr(0, 1).toUpperCase();
    return `${this.firstName} ${this.lastName}`;
  }

  logout(): void {
    this.authService.logout();
    this.localStorageService.clear();
    this.router.navigateByUrl('login');
  }
}
