import { Component, OnInit, Input, Output, EventEmitter, HostListener, OnChanges, SimpleChanges } from '@angular/core';
import { FileUploadType } from './file-upload';
import { forEach } from 'lodash';

@Component({
  selector: 'app-file-upload-drag-drop',
  templateUrl: './file-upload-drag-drop.component.html',
  styleUrls: ['./file-upload-drag-drop.component.scss'],
})
export class FileUploadDragDropComponent implements OnInit, OnChanges {
  errors: Array<string> = [];
  hasFiles = false;
  filename: string;

  dragAreaClass = 'dragarea';
  @Input() projectId = 0;
  @Input() sectionId = 0;
  @Input() fileExt = ['jpg', 'gif', 'png', 'xls', 'xlsx'];
  @Input() maxFiles = 5;
  @Input() maxSize = 5; // 5MB
  @Input() thumbnail = null;
  @Output() enableUpload = new EventEmitter<boolean>();

  @Output() fileChanges: EventEmitter<any> = new EventEmitter<File[]>();

  thumbnailImg = '';

  constructor() {}

  get defaultThumbnail(): string {
    return this.thumbnail ? this.thumbnail : '../../../../assets/img/upload.svg';
  }

  ngOnInit() {
    this.thumbnailImg = this.defaultThumbnail;
  }

  ngOnChanges(changes: SimpleChanges) {
    const thumbnailChanges = changes.thumbnail;
    if (thumbnailChanges) {
      this.thumbnailImg = this.defaultThumbnail;
    }
  }

  onFileChange(event) {
    this.validateFiles(event.target.files);
  }

  @HostListener('dragover', ['$event']) onDragOver(event) {
    this.dragAreaClass = 'droparea';
    event.preventDefault();
  }

  @HostListener('dragenter', ['$event']) onDragEnter(event) {
    this.dragAreaClass = 'droparea';
    event.preventDefault();
  }

  @HostListener('dragend', ['$event']) onDragEnd(event) {
    this.dragAreaClass = 'dragarea';
    event.preventDefault();
  }

  @HostListener('dragleave', ['$event']) onDragLeave(event) {
    this.dragAreaClass = 'dragarea';
    event.preventDefault();
  }

  @HostListener('drop', ['$event']) onDrop(event) {
    this.dragAreaClass = 'dragarea';
    event.preventDefault();
    event.stopPropagation();
    this.validateFiles(event.dataTransfer.files);
  }

  async validateFiles(files: any[]) {
    if (!files.length) {
      return;
    }

    this.errors = []; // Clear error

    this.hasFiles = true;
    const fileType = new FileUploadType(files[0]);
    this.thumbnailImg = await fileType.GetFileThumbnail();
    this.filename = fileType.GetFileName();

    if (files.length > this.maxFiles) {
      this.errors.push('Error: At a time you can upload only ' + this.maxFiles + ' files');
      return;
    }

    forEach(files, (file) => {
      this.isValidFileSize(file);
      this.isValidFileExtension(file);
    });

    this.enableUpload.emit(this.errors.length === 0);
    this.fileChanges.emit(files);
  }

  private isValidFileExtension(file) {
    const fileExt = new FileUploadType(file).GetFileExtension();
    if (this.fileExt.indexOf(fileExt) < 0) {
      this.errors.push(
        'Error (File Ext): ' + file.name + ': should be on the following format: ' + this.fileExt.join(', ')
      );
    }
  }

  private isValidFileSize(file) {
    const fileSizeinMB = file.size / (1024 * 1000);
    const size = Math.round(fileSizeinMB * 100) / 100; // convert upto 2 decimal place
    if (size > this.maxSize) {
      this.errors.push(
        'Error (File Size): ' + file.name + ': exceed file size limit of ' + this.maxSize + 'MB ( ' + size + 'MB )'
      );
    }
  }

  removeUpload() {
    this.errors = [];
    this.hasFiles = false;
    this.filename = '';
    this.fileChanges.emit([]);
    this.enableUpload.emit(false);
    this.thumbnailImg = this.defaultThumbnail;
  }
}
