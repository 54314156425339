import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { LOGIN_STATE } from './loginstates';
import { AuthService } from '../core/services/auth.service';
import { switchMap, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { NgxPermissionsService } from 'ngx-permissions';
import { GetUserPermissions } from '../user-management/users';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  txtUser: string;
  txtPass: string;
  txtSecurityQuestion: string;
  txtSecurityAnswer: string;
  txtExistPassword: string;
  txtNewPassword: string;
  txtPin: string;

  message: string;
  returnUrl: string;
  currentLoginState: LOGIN_STATE;
  loginState = LOGIN_STATE;

  isErrorMessage: boolean;

  securityQuestion: string;
  submitText: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private permissionsService: NgxPermissionsService
  ) {}

  ngOnInit() {
    this.authService.logout();
    this.changeState(LOGIN_STATE.LOGPASSWORD);
    this.returnUrl = this.route.snapshot.queryParams.returnUrl || '/';
    this.txtUser = '';
    this.txtPass = '';
  }

  onSubmit(): void {
    switch (this.currentLoginState) {
      case LOGIN_STATE.LOGPASSWORD:
        {
          this.logPassword(this.txtUser, this.txtPass);
        }
        break;
      case LOGIN_STATE.LOGSECURITYQUESTION:
        {
          this.logSecurityAnswer(this.txtSecurityAnswer);
        }
        break;
      case LOGIN_STATE.FORGOTPASSWORD:
        {
          this.forgotPassword(this.txtUser);
        }
        break;
      case LOGIN_STATE.RESETPASSWORD:
        {
          this.changePassword(this.txtUser, this.txtExistPassword, this.txtNewPassword);
        }
        break;
      case LOGIN_STATE.EMAILSENT:
        {
          this.changeState(LOGIN_STATE.LOGPASSWORD);
        }
        break;
      case LOGIN_STATE.CHANGEPASSWORD:
        {
          this.changePassword(this.txtUser, this.txtExistPassword, this.txtNewPassword);
        }
        break;
      case LOGIN_STATE.CHANGESECURITYQUESTION:
        {
          this.setupSecurityQuestion(this.txtSecurityQuestion, this.txtSecurityAnswer);
        }
        break;
      case LOGIN_STATE.CHANGEPIN:
        {
          this.changePin(this.txtPin);
        }
        break;
    }
  }

  forgotPassword(userId: string): void {
    this.authService.forgotPassword(userId).subscribe(
      (result) => {
        this.changeState(LOGIN_STATE.EMAILSENT);
      },
      (error) => {
        this.setErrorMessage('Invalid credentials.');
      }
    );
  }

  changePassword(userId: string, existingPassword: string, newPassword: string): void {
    this.authService.changePassword(existingPassword, newPassword).subscribe(
      () => {
        const isVerified = JSON.parse(localStorage.getItem('currentUser')).verifiedAccount;
        if (isVerified) {
          this.redirectUser();
        } else {
          this.changeState(LOGIN_STATE.CHANGESECURITYQUESTION);
        }
      },
      (result) => {
        this.setErrorMessage('Server Error: ' + result.error);
      }
    );
  }

  changePin(pin: string): void {
    this.authService.changePin(pin).subscribe(
      () => {
        this.redirectUser();
      },
      (result) => {
        this.setErrorMessage('Server Error: ' + result.error);
      }
    );
  }

  setupSecurityQuestion(question: string, answer: string): void {
    this.authService.setupSecurityQuestion(question, answer).subscribe(
      () => {
        this.changeState(LOGIN_STATE.CHANGEPIN);
      },
      (result) => {
        console.log(result);
        this.setErrorMessage('Server Error: ' + result.data.error);
      }
    );
  }

  logPassword(userId: string, password: string): void {
    this.authService.login(userId, password).subscribe(
      (result) => {
        if (result.token !== undefined) {
          this.checkIfFirstTimeLogIn();
        } else {
          this.setErrorMessage('Invalid credentials.');
        }
      },
      (result) => {
        console.log(result);
        if (result.status === 401) {
          this.setErrorMessage('Invalid Credentials');
        } else {
          this.setErrorMessage('Server encountered an error.');
        }
      }
    );
  }

  checkIfFirstTimeLogIn(): void {
    this.authService.getUserInfo().subscribe((result) => {
      if (result.record) {
        const isVerified = result.record.verifiedAccount;
        if (isVerified) {
          this.getSecurityQuestion();
        } else {
          this.changeState(LOGIN_STATE.CHANGEPASSWORD);
        }
      }
    });
  }

  getSecurityQuestion(): void {
    this.authService.getSecurityQuestion().subscribe(
      (result) => {
        if (result.question !== undefined) {
          this.securityQuestion = result.question;
          this.changeState(LOGIN_STATE.LOGSECURITYQUESTION);
        }
      },
      (result) => {
        this.setErrorMessage('Server Error: ' + result.error);
      }
    );
  }

  logSecurityAnswer(answer: string): void {
    this.authService
      .verifySecurityAnswer(answer)
      .pipe(
        switchMap(() => this.authService.getUserInfo()),
        switchMap((x) => this.authService.getUserPermissions(x.record.userId)),
        catchError(() => {
          this.setErrorMessage('Wrong Answer');
          return of({} as GetUserPermissions);
        })
      )
      .subscribe((permissions) => {
        localStorage.setItem('permissions', JSON.stringify(permissions.records));

        this.permissionsService.loadPermissions(permissions.records);
        this.redirectUser();
      });
  }

  redirectUser(): void {
    this.router.navigate(['dashboard']);
  }

  clearFields(): void {
    this.txtPass = '';
    this.txtSecurityQuestion = '';
    this.txtSecurityAnswer = '';
    this.txtExistPassword = '';
    this.txtNewPassword = '';
    this.txtPin = '';
  }

  clearMessage(): void {
    this.message = '';
    this.isErrorMessage = false;
  }

  setMessage(message: string): void {
    this.message = message;
    this.isErrorMessage = false;
  }

  setErrorMessage(message: string): void {
    this.message = message;
    this.isErrorMessage = true;
  }

  changeState(state: LOGIN_STATE): void {
    if (state !== this.currentLoginState) {
      switch (state) {
        case LOGIN_STATE.LOGPASSWORD:
          {
            this.setMessage('Log in to your account');
            this.submitText = 'Submit';
          }
          break;
        case LOGIN_STATE.LOGSECURITYQUESTION:
          {
            this.setMessage('Help us verify your identity by answering the security question below.');
            this.submitText = 'Log In';
          }
          break;
        case LOGIN_STATE.FORGOTPASSWORD:
          {
            this.setMessage('Enter your username and we will send you an email on how to reset your password.');
            this.submitText = 'Send Request';
          }
          break;
        case LOGIN_STATE.RESETPASSWORD:
          {
            this.setMessage('Reset your password');
            this.submitText = 'Reset Password';
          }
          break;
        case LOGIN_STATE.EMAILSENT:
          {
            this.setMessage('We have sent a password reset instruction to your email');
            this.submitText = 'Done';
          }
          break;
        case LOGIN_STATE.CHANGEPASSWORD:
          {
            this.setMessage('Update your password');
            this.submitText = 'Update Password';
          }
          break;
        case LOGIN_STATE.CHANGESECURITYQUESTION:
          {
            this.setMessage('Set up security question');
            this.submitText = 'Reset Password';
          }
          break;
        case LOGIN_STATE.CHANGEPIN:
          {
            this.setMessage('Set up your 4 PIN Code');
            this.submitText = 'Set PIN';
          }
          break;
      }
      this.currentLoginState = state;
      this.clearFields();
      this.txtSecurityAnswer = '';
    }
  }
}
