import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { sum } from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class ReportService {
  constructor(private http: HttpClient) {}

  getLoaStatusByHMO(): Observable<any> {
    const body = {
      routine: 'reportHmoLoaStatusByMedicalProvider',
      parameters: '',
    };

    return this.http.post<any>('reports/GetReportData', body).pipe(
      map((res) => res.records),
      map((records) => {
        return {
          medicalProviders: records.map((x) => x.MedicalProvider),
          approved: records.map((x) => x.Approved),
          confirmed: records.map((x) => x.Confirmed),
          transmitted: records.map((x) => x.Transmitted),
        };
      })
    );
  }

  getLoaAging() {
    const body = {
      routine: 'reportHmoLoaAging',
      parameters: '',
    };

    return this.http.post<any>('reports/GetReportData', body).pipe(
      map((res) => res.records),
      map((records) => {
        return {
          period: ['Current', 'Past 30 Days', 'Pass 60 Days', 'Past 90 Days', 'Over 90 Days'],
          amount: [
            sum(records.map((x) => x.Current)),
            sum(records.map((x) => x.Past30Days)),
            sum(records.map((x) => x.Past60Days)),
            sum(records.map((x) => x.Past90Days)),
            sum(records.map((x) => x['>90Days'])),
          ],
        };
      })
    );
  }

  getReportData<T>(routine: string, parameters: string) {
    const body = {
      routine,
      parameters,
    };

    return this.http.post<any>('reports/GetReportData', body).pipe(
      map((res) => res.records),
      map((records) => {
        return <T>records;
      })
    );
  }
}
