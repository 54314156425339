import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';

@Injectable({
  providedIn: 'root'
})
export class MessagingService {

  SUCCESS = 'success';
  SUCCESS_HEADER = 'Success Message';
  ERROR = 'error';
  ERROR_HEADER = 'Error Message';
  WARN = 'warn';
  WARN_HEADER = 'Warning Message';

  constructor(private messageService: MessageService) { }

  errorMessage(message: string) {
    this.messageService.add({ severity: this.ERROR, summary: this.ERROR_HEADER, detail: message });
  }
  warningMessage(message: string) {
    this.messageService.add({ severity: this.WARN, summary: this.WARN_HEADER, detail: message });
  }
  successMessage(message: string) {
    this.messageService.add({ severity: this.SUCCESS, summary: this.SUCCESS_HEADER, detail: message });
  }
}
