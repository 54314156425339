import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { PdfDialogComponent } from 'src/app/shared/components/pdf-dialog/pdf-dialog.component';
import { ZXingScannerComponent } from '@zxing/ngx-scanner';
import { MessageService } from 'primeng/api';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-scan-code',
  templateUrl: './scan-code.component.html',
  styleUrls: ['./scan-code.component.scss']
})
export class ScanCodeComponent implements OnInit {
  isQr: boolean;
  scanImg: string;
  scanSuccess: boolean;
  hasPermission: boolean;
  form = new FormGroup({
    pinCode: new FormControl('', Validators.required)
  });
  pin: string;
  @ViewChild('scanner', { static: false })
  scanner: ZXingScannerComponent = new ZXingScannerComponent();

  constructor(public dialogRef: MatDialogRef<PdfDialogComponent>, public messageService: MessageService) {}

  ngOnInit() {
    this.isQr = false;
    this.pin = '';
    this.scanner.camerasNotFound.subscribe((devices: MediaDeviceInfo[]) => {
      console.error('An error has occurred when trying to enumerate your video-stream-enabled devices.');
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'No video-stream enabled device detected.'
      });
    });

    this.scanner.permissionResponse.subscribe((answer: boolean) => {
      this.hasPermission = answer;
      if (!this.hasPermission) {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Please allow use of camera to proceed with scanning.'
        });
      }
    });
  }

  submit() {
    if (!this.isQr) {
      this.pin = this.form.value.pinCode;
    }
    this.dialogRef.close(this.pin);
  }

  handleQrCodeResult(resultString: string) {
    // workaround for pass by value
    this.pin = resultString + '';
    this.scanSuccess = true;
  }

  selectCode(type: string): void {
    this.isQr = type === 'qr';
  }
}
