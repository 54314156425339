import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardModule } from 'primeng/card';
import { SearchComponent } from './components/search/search.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SelectableListComponent } from './components/selectable-list/selectable-list.component';
import { LoaderComponent } from './components/loader/loader.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { HeaderTitleComponent } from './components/header-title/header-title.component';
import { PaginatorComponent } from './components/paginator/paginator.component';
import { MatSortModule } from '@angular/material/sort';
import { MatDialogModule } from '@angular/material/dialog';
import { FileUploadDragDropComponent } from './components/file-upload-drag-drop/file-upload-drag-drop.component';
import { LoeViewerComponent } from './components/loe-viewer/loe-viewer.component';
import { PdfDialogComponent } from './components/pdf-dialog/pdf-dialog.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTreeModule } from '@angular/material/tree';
import { LocalDatePipe } from './pipes/local-date.pipe';
import { LocalNumberPipe } from './pipes/local-number.pipe';
import { LocalCurrencyPipe } from './pipes/local-currency.pipe';
import { NgxPermissionsModule } from 'ngx-permissions';
import { TreeComponent } from './components/tree/tree.component';
import { ProductPackageContentComponent } from './components/product-package-content/product-package-content.component';
import { DialogModule } from 'primeng/dialog';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { TreeModule } from 'primeng/tree';
import { ScanCodeComponent } from './components/scan-code/scan-code.component';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { ImageCropperModule } from 'ngx-image-cropper';
import { ToastModule } from 'primeng/toast';
import { TruncatePipe } from './pipes/truncate.pipe';
import { DropdownModule } from 'primeng/dropdown';
import { UploadImageComponent } from './components/upload-image/upload-image.component';

@NgModule({
  declarations: [
    SearchComponent,
    SelectableListComponent,
    LoaderComponent,
    HeaderTitleComponent,
    PaginatorComponent,
    FileUploadDragDropComponent,
    LoeViewerComponent,
    PdfDialogComponent,
    LocalDatePipe,
    LocalNumberPipe,
    LocalCurrencyPipe,
    TruncatePipe,
    TreeComponent,
    ProductPackageContentComponent,
    ScanCodeComponent,
    UploadImageComponent,
  ],
  imports: [
    CommonModule,
    CardModule,
    FormsModule,
    MatProgressSpinnerModule,
    MatSortModule,
    MatDialogModule,
    MatTableModule,
    MatTreeModule,
    MatInputModule,
    MatSelectModule,
    MatCheckboxModule,
    MatIconModule,
    MatTabsModule,
    MatChipsModule,
    MatAutocompleteModule,
    MatExpansionModule,
    MatProgressSpinnerModule,
    DialogModule,
    ConfirmDialogModule,
    ToastModule,
    DropdownModule,
    TreeModule,
    ReactiveFormsModule,
    ZXingScannerModule,
    ImageCropperModule,
  ],
  exports: [
    CardModule,
    FormsModule,
    HeaderTitleComponent,
    SearchComponent,
    SelectableListComponent,
    LoaderComponent,
    PaginatorComponent,
    FileUploadDragDropComponent,
    MatSortModule,
    MatDialogModule,
    LoeViewerComponent,
    PdfDialogComponent,
    LocalDatePipe,
    LocalNumberPipe,
    TruncatePipe,
    LocalCurrencyPipe,
    NgxPermissionsModule,
    TreeComponent,
    MatInputModule,
    MatTabsModule,
    MatChipsModule,
    MatAutocompleteModule,
    MatExpansionModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    ProductPackageContentComponent,
    ReactiveFormsModule,
    ScanCodeComponent,
    ToastModule,
    DropdownModule,
    UploadImageComponent,
  ],
  entryComponents: [PdfDialogComponent],
})
export class SharedModule {}
