import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { LoaderService } from 'src/app/core/services/loader.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent implements OnInit {
  color = 'primary';
  mode = 'indeterminate';
  value = 50;
  isLoading: boolean;
  isHardLoading = false;

  constructor(private loaderService: LoaderService) {
    this.loaderService.isLoading.subscribe((x) => {
      this.isLoading = x;
    });

    this.loaderService.isHardLoading.subscribe((x) => {
      this.isHardLoading = x;
    });
  }

  ngOnInit() {}
}
