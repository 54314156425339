import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

const Globalize = require('globalize');

@Pipe({
  name: 'localDate'
})
export class LocalDatePipe implements PipeTransform {

  transform(value: Date, format: string = 'yMMdd') {

    if (!value) { return ''; }
    const formatter = Globalize.dateFormatter({ skeleton: format });
    const date = moment(value).local();
    return formatter(date.toDate());

  }

}
