import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ReportService } from '../shared/services/report.service';
import { BarChartService } from '../shared/services/bar-chart.service';
import { sum } from 'lodash';
import Chart from 'chart.js';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  @ViewChild('barChartArea', { static: true }) private barChartAreaRef: ElementRef;
  @ViewChild('pieChartArea', { static: true }) private pieChartAreaRef: ElementRef;
  barChart: any;
  pieChart: any;
  constructor(
    private reportService: ReportService,
    private barChartService: BarChartService
  ) { }

  ngOnInit() {
    this.getDashBoardReports();
    this.getLOAAgingReport();
  }

  private getLOAAgingReport() {
    this.reportService.getLoaAging()
      .subscribe((x) => {
        console.log(x);
        const chartColors = [
          '#5b9bd5',
          '#ed7d31',
          '#a5a5a5',
          '#ffc000',
          '#c00000'
        ];
        const labels = x.period;
        const data = x.amount;

        if (this.pieChart !== undefined) {
          this.pieChart.destroy();
        }

        this.pieChart = new Chart(this.pieChartAreaRef.nativeElement, {
          type: 'pie',
          data: {
            labels,
            datasets: [
              {
                data,
                borderColor: chartColors,
                backgroundColor: chartColors,
              }
            ]
          },
          options: {
            title: {
              display: true,
              text: 'Accounts Payable Aging'
            }
          }
        });
      });
  }

  private getDashBoardReports() {
    this.reportService.getLoaStatusByHMO()
      .subscribe((x) => {
        console.log(x);
        const chartColors = [
          '#00b050',
          '#f4b081',
          '#ffff00'
        ];
        x.medicalProviders.unshift('Total');
        x.approved.unshift(sum(x.approved));
        x.confirmed.unshift(sum(x.confirmed));
        x.transmitted.unshift(sum(x.transmitted));
        const datasets = [
          {
            backgroundColor: chartColors[0],
            data: x.approved,
            label: 'Approved',
          },
          {
            backgroundColor: chartColors[1],
            data: x.confirmed,
            label: 'Confirmed',
          },
          {
            backgroundColor: chartColors[2],
            data: x.transmitted,
            label: 'Transmitted',
          }
        ];
        if (this.barChart !== undefined) {
          this.barChart.destroy();
        }
        this.barChart = this.barChartService.draw(
          this.barChartAreaRef,
          x.medicalProviders,
          this.barChartService.defaultLabelStyle('Medical Providers', (label) => label),
          datasets,
          true);
      });
  }
}
