import { Injectable } from '@angular/core';
import { StorageService } from '../models/storage-service';

/**
 *  Encapsulates functionality for managing local storage.
 */
@Injectable({
  providedIn: 'root'
})
export class LocalStorageService extends StorageService {

  constructor() {
    super();

    // :: TODO fallback to something else if local storage is not available
    if (typeof (Storage) === 'undefined') {
      console.error(':: Local storage not available.');
    }
  }

  setItem(key, value) {
    // :: check if value has differed
    const currentValue = localStorage.getItem(key);
    if (currentValue === value) {
      return;
    }

    localStorage.setItem(key, value);
    this.getSubject(key).next(value);
  }

  clear() {

    // removing below because it is no longer needed, plus it is affecting the My Quick Links
    // saving in local storage not persisting when session refresh
    // localStorage.clear()

    super.clear();
  }

  removeItem(key: string): any {
    localStorage.removeItem(key);
  }

  // :: ---

  getItem(key: string): any {
    return localStorage.getItem(key);
  }
}
