import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { LookupResult } from '../../models/lookup';
import { isNil, clone, isEmpty } from 'lodash';

@Component({
  selector: 'app-selectable-list',
  templateUrl: './selectable-list.component.html',
  styleUrls: ['./selectable-list.component.scss']
})
export class SelectableListComponent implements OnInit {

  @Input() items: LookupResult[];
  @Output() select = new EventEmitter<LookupResult>();

  list: LookupResult[] = [];
  isResultHidden = true;

  constructor() { }

  ngOnInit() {
    this.list = clone(this.items);
  }


  searchList(searchText): boolean {
    this.list = isNil(searchText) ? this.items : this.items.filter(item => item.name.toLowerCase().indexOf(searchText.toLowerCase()) > -1);
    this.isResultHidden = false;

    return isNil(this.list) || isEmpty(this.list);
  }

  onSelect(item) {
    this.isResultHidden = true;
    this.select.emit(item);
  }
}
