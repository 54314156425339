import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { TreeNode, ConfirmationService } from 'primeng/api';
import {
  ProductPackageTransactionType,
  ProductPackageProduct,
  ProductPackageServiceGroup,
} from '../../../product-package-management/models/product-packages.model';
import { LookupResultByCode, LookupResultByCategory } from 'src/app/shared/models/lookup';
import { cloneDeep, orderBy } from 'lodash';

@Component({
  selector: 'app-product-package-content',
  templateUrl: './product-package-content.component.html',
  styleUrls: ['./product-package-content.component.scss'],
  providers: [ConfirmationService],
})
export class ProductPackageContentComponent implements OnInit, OnChanges {
  isFirstLoad = true;
  isLoading = false;
  deleteName = 'TOBEDELETED';

  @Output() loadingChange = new EventEmitter();
  set loading(val: boolean) {
    this.isLoading = val;
    this.loadingChange.emit(this.isLoading);
  }
  @Input() get loading() {
    return this.isLoading;
  }

  packageContent: ProductPackageTransactionType[] = [];
  @Output() contentFormChange = new EventEmitter<ProductPackageTransactionType[]>();
  set contentForm(val: ProductPackageTransactionType[]) {
    this.packageContent = val;
    this.contentFormChange.emit(this.packageContent);
  }
  @Input() get contentForm() {
    return this.packageContent;
  }

  @Input() transactionTypes: LookupResultByCode[];
  @Input() serviceGroups: LookupResultByCategory[];
  @Input() productList: ProductPackageProduct[] = [];

  @Input() markAsDeleteOnly = false;

  contentTree: TreeNode[] = [];

  selectedContents: TreeNode[] = [];
  cleanContent: ProductPackageTransactionType[] = [];

  showDialog = false;
  addTransactionTypeField: LookupResultByCode[] = [];
  addServiceGroupField: LookupResultByCategory[] = [];
  addProductsField: ProductPackageProduct[] = [];

  constructor(private confirmService: ConfirmationService) {}

  ngOnInit() {
    if (this.contentForm.length === 0) {
      this.convertToTreeData([]);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.contentForm.length > 0 && this.isFirstLoad) {
      this.contentForm.map((contents) => {
        const newContent = cloneDeep(contents);
        this.cleanContent.push(newContent);
      });
      this.convertToTreeData(this.contentForm);
      this.isFirstLoad = false;
    }
  }

  openDialog() {
    this.showDialog = true;
    this.addTransactionTypeField = [];
    this.addServiceGroupField = [];
    this.addProductsField = [];
  }

  closeDialog(isSave: boolean) {
    this.showDialog = false;
    if (isSave) {
      if (this.addTransactionTypeField.length > 0) {
        const isEmpty =
          this.contentTree.find((treeNode) => treeNode.label === 'No Product package content found.') !== undefined;
        if (isEmpty) {
          this.contentTree = [];
        }
        this.addTransactionTypeField.map((transactionTypeField) => {
          const existingTransactionType = this.contentForm.find((tt) => tt.code === transactionTypeField.code);
          const existingTTContent = this.contentTree.find((tt) => tt.data.code === transactionTypeField.code);

          const transactionType: ProductPackageTransactionType = existingTransactionType || {
            id: '',
            code: transactionTypeField.code,
            name: transactionTypeField.description,
            serviceGroups: [],
          };

          const ttContent: TreeNode = existingTTContent || {
            label: transactionType.name,
            data: {
              type: 'tt',
              id: transactionType.id,
              code: transactionType.code,
              name: transactionType.name,
            },
            expandedIcon: 'fa fa-folder-open',
            collapsedIcon: 'fa fa-folder',
            children: [],
          };

          if (this.addServiceGroupField.length > 0) {
            this.addServiceGroupField.map((selectedSg) => {
              const existingServiceGroup = transactionType.serviceGroups.find((sg) => sg.code === selectedSg.code);
              const existingSGContent = ttContent.children.find((sg) => sg.data.code === selectedSg.code);

              const serviceGroup: ProductPackageServiceGroup = existingServiceGroup || {
                id: '',
                code: selectedSg.code,
                name: selectedSg.description,
                products: [],
              };

              const sgContent: TreeNode = existingSGContent || {
                label: serviceGroup.name,
                data: {
                  type: 'sg',
                  parent: ttContent.data,
                  id: serviceGroup.id,
                  code: serviceGroup.code,
                  name: serviceGroup.name,
                },
                expandedIcon: 'fa fa-folder-open',
                collapsedIcon: 'fa fa-folder',
                children: [],
              };

              if (this.addProductsField.length > 0) {
                this.addProductsField.map((newProduct) => {
                  if (newProduct.serviceGroupCode === serviceGroup.code) {
                    if (
                      !serviceGroup.products.find(
                        (ep) => ep.startCode === newProduct.startCode && ep.endCode === newProduct.endCode
                      )
                    ) {
                      const newProductAdd = { ...newProduct, id: '' };

                      serviceGroup.products.push(newProductAdd);
                    }

                    if (
                      !sgContent.children.find(
                        (ep) => ep.data.startCode === newProduct.startCode && ep.data.endCode === newProduct.endCode
                      )
                    ) {
                      const productTreeNode: TreeNode = {};
                      productTreeNode.label = newProduct.name;
                      productTreeNode.data = {
                        type: 'p',
                        parent: sgContent.data,
                        id: newProduct.id,
                        name: newProduct.name,
                        startCode: newProduct.startCode,
                        endCode: newProduct.endCode,
                      };

                      sgContent.children.push(productTreeNode);
                    }
                  }
                });
              }

              if (!existingServiceGroup) {
                transactionType.serviceGroups.push(serviceGroup);
                ttContent.children.push(sgContent);
              }
            });
          }

          if (!existingTransactionType) {
            this.contentForm.push(transactionType);
            this.contentTree.push(ttContent);
          }
        });
      }
    }
  }

  removeContent() {
    this.selectedContents.map((content) => {
      const contentData = content.data;
      if (contentData.type === 'p') {
        const pParentData = contentData.parent;
        const sgParentData = contentData.parent.parent;
        this.contentForm.map((tt) => {
          if (tt.id === sgParentData.id && tt.code === sgParentData.code) {
            tt.serviceGroups.map((sg) => {
              if (sg.id === pParentData.id && sg.code === pParentData.code) {
                // Only mark as delete on existing. If new created, use filter.
                if (contentData.id && this.markAsDeleteOnly) {
                  sg.products.map((p) => (p.name = p.id === contentData.id ? this.deleteName : p.name));
                } else {
                  sg.products = sg.products.filter((p) => {
                    return (
                      p.id !== contentData.id ||
                      p.startCode !== contentData.startCode ||
                      p.endCode !== contentData.endCode
                    );
                  });
                }
              }
            });
          }
        });

        this.contentTree.map((tt) => {
          if (tt.data.id === sgParentData.id && tt.data.code === sgParentData.code) {
            tt.children.map((sg) => {
              if (sg.data.id === pParentData.id && sg.data.code === pParentData.code) {
                sg.children = sg.children.filter((p) => {
                  return (
                    p.data.id !== contentData.id ||
                    p.data.startCode !== contentData.startCode ||
                    p.data.endCode !== contentData.endCode
                  );
                });
              }
            });
          }
        });
      } else if (contentData.type === 'sg') {
        const sgParentData = contentData.parent;
        this.contentForm.map((tt) => {
          if (tt.id === sgParentData.id && tt.code === sgParentData.code) {
            // Only mark as delete on existing. If new created, use filter.
            if (contentData.id && this.markAsDeleteOnly) {
              tt.serviceGroups.map((sg) => (sg.name = sg.id === contentData.id ? this.deleteName : sg.name));
            } else {
              tt.serviceGroups = tt.serviceGroups.filter((sg) => {
                return sg.id !== contentData.id || sg.code !== contentData.code;
              });
            }
          }
        });

        this.contentTree.map((tt) => {
          if (tt.data.id === sgParentData.id && tt.data.code === sgParentData.code) {
            tt.children = tt.children.filter((sg) => {
              return sg.data.id !== contentData.id || sg.data.code !== contentData.code;
            });
          }
        });
      } else if (contentData.type === 'tt') {
        // Only mark as delete on existing. If new created, use filter.
        if (contentData.id && this.markAsDeleteOnly) {
          this.contentForm.map((tt) => (tt.name = tt.id === contentData.id ? this.deleteName : tt.name));
        } else {
          this.contentForm = this.contentForm.filter((tt) => {
            return tt.id !== contentData.id || tt.code !== contentData.code;
          });
        }

        this.contentTree = this.contentTree.filter((tt) => {
          return tt.data.id !== contentData.id || tt.data.code !== contentData.code;
        });
      }
    });
    const isEmpty = this.contentTree.length === 0;
    if (isEmpty) {
      this.contentTree.push({
        label: 'No Product package content found.',
        selectable: false,
      });
    }
    this.selectedContents = [];
  }

  resetChanges() {
    this.confirmService.confirm({
      header: 'Reset changes',
      message: 'Do you want to reset your changes?',
      accept: () => {
        this.loading = true;
        this.selectedContents = [];
        this.contentForm = [];
        this.cleanContent.map((contents) => {
          const newContent = cloneDeep(contents);
          this.contentForm.push(newContent);
        });
        this.convertToTreeData(this.contentForm);
        this.loading = false;
      },
    });
  }

  convertToTreeData(content: ProductPackageTransactionType[]) {
    this.contentTree = [];
    if (content.length === 0) {
      this.contentTree.push({
        label: 'No Product package content found.',
        selectable: false,
      });
      return;
    }
    content.map((transactionType) => {
      if (transactionType.description !== this.deleteName) {
        const ttTreeNode: TreeNode = {};
        ttTreeNode.label = transactionType.name;
        ttTreeNode.data = {
          type: 'tt',
          id: transactionType.id,
          code: transactionType.code,
          name: transactionType.name,
        };
        ttTreeNode.expandedIcon = 'fa fa-folder-open';
        ttTreeNode.collapsedIcon = 'fa fa-folder';
        ttTreeNode.children = [];

        transactionType.serviceGroups.map((serviceGroup) => {
          if (serviceGroup.description !== this.deleteName) {
            const sgTreeNode: TreeNode = {};
            sgTreeNode.label = serviceGroup.name;
            sgTreeNode.data = {
              type: 'sg',
              parent: ttTreeNode.data,
              id: serviceGroup.id,
              code: serviceGroup.code,
              name: serviceGroup.name,
            };
            sgTreeNode.expandedIcon = 'fa fa-folder-open';
            sgTreeNode.collapsedIcon = 'fa fa-folder';
            sgTreeNode.children = [];

            serviceGroup.products.map((product) => {
              if (product.product !== this.deleteName) {
                const productTreeNode: TreeNode = {};
                productTreeNode.label = product.name;
                productTreeNode.data = {
                  type: 'p',
                  parent: sgTreeNode.data,
                  id: product.id,
                  name: product.name,
                  startCode: product.startCode,
                  endCode: product.endCode,
                };

                sgTreeNode.children.push(productTreeNode);
              }
            });

            ttTreeNode.children.push(sgTreeNode);
          }
        });

        this.contentTree.push(ttTreeNode);
      }
    });
  }

  getFilteredProductList(serviceGroupCode: LookupResultByCategory[]) {
    if (serviceGroupCode && serviceGroupCode.length > 0) {
      return orderBy(
        this.productList.filter((product) => {
          return serviceGroupCode.findIndex((sg) => sg.code === product.serviceGroupCode) > -1;
        }),
        'serviceGroupCode'
      );
    }
    return [];
  }
}
