export enum LOGIN_STATE {
  FIRSTTIME,
  LOGPASSWORD,
  LOGSECURITYQUESTION,
  LOGPIN,
  CHANGEPASSWORD,
  CHANGEPIN,
  CHANGESECURITYQUESTION,
  FORGOTPASSWORD,
  RESETPASSWORD,
  EMAILSENT
}
