import { Pipe, PipeTransform } from '@angular/core';

const Globalize = require('globalize/dist/globalize/currency');

@Pipe({
  name: 'localCurrency'
})
export class LocalCurrencyPipe implements PipeTransform {

  constructor() {
    Globalize.load(
      require('cldr-data/main/en/currencies.json'),
      require('cldr-data/supplemental/currencyData.json')
    );
  }

  transform(value: number) {

    if (!value) { return ''; }

    return Globalize.formatCurrency(value, 'PHP');
  }

}
