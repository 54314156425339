export class FileUploadType {
  private icon: string;
  private extension: string;
  constructor(private file: any) {}
  GetFileExtension(): string {
    if (this.file.name) {
      return this.file.name.split('.').pop();
    }
    return 'no file initialized';
  }
  GetFileName(): string {
    if (this.file.name) {
      return this.file.name.split('/').pop();
    }
    return 'no file initialized';
  }
  async GetFileThumbnail(): Promise<string> {
    const p = new Promise<string>(async (resolve, reject) => {
      if (this.file.name) {
        const ext = this.GetFileExtension();
        switch (ext.toLowerCase()) {
          case 'xls':
          case 'xlsx':
          case 'xlsm':
            resolve('../../../../assets/img/excel.svg');
            break;
          case 'txt':
            resolve('../../../../assets/img/notepad.svg');
            break;
          case 'doc':
          case 'docx':
          case 'docm':
            resolve('../../../../assets/img/word.svg');
            break;
          case 'pdf':
            resolve('../../../../assets/img/pdf.svg');
            break;
          case 'jpg':
          case 'jpeg':
          case 'png':
            resolve(await this.getImageUrl());
            break;

          default:
            resolve('../../../../assets/img/document.svg');
            break;
        }
      }
      reject('no file initialized');
    });

    return p;
  }

  private async getImageUrl(): Promise<string> {
    const p = new Promise<string>((resolve, reject) => {
      const fr = new FileReader();
      fr.onload = (e) => {
        resolve(fr.result.toString());
      };

      fr.readAsDataURL(this.file);
    });

    return p;
  }
}
