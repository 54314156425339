export function setDecimalPlaces(data) {
    const mappedValues = data.map(x => x);
    const maxValue = Math.max(...mappedValues);
    // tslint:disable-next-line: one-variable-per-declaration
    let precision, decimal;
    if (maxValue < 0.01) {
        precision = Math.ceil(maxValue) / 1000;
        decimal = 3;
    } else if (maxValue < 0.1) {
        precision = Math.ceil(maxValue) / 100;
        decimal = 2;
    } else if (maxValue < 10) {
        precision = Math.ceil(maxValue) / 10;
        decimal = 1;
    } else {
        precision = null;
        decimal = 0;
    }
    return {
        precision,
        decimal
    };
}
