import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {

  @Input() searchText: string;
  @Input() placeholder: string;
  @Output() search = new EventEmitter<string>();

  constructor() { }

  ngOnInit() {
    this.placeholder = this.placeholder || 'Search';
  }

  triggerSearch() {
    this.search.emit(this.searchText);
  }
}
