import { BehaviorSubject } from 'rxjs';
import { keys } from 'lodash';

export abstract class StorageService {

  protected subjects: any = {};

  constructor() {
  }

  public getItem$ = (key: string) => this.getSubject(key);

  protected abstract getItem(key: string);
  protected abstract setItem(key: string, value: any);
  protected clear() {
    keys(this.subjects).forEach(key => this.getSubject(key).next(null));
  }

  // :: ---

  protected getSubject(key: string) {
    if (!this.subjects[key]) {
      const currentValue = this.getItem(key);
      const subject = new BehaviorSubject<any>(currentValue);
      this.subjects[key] = subject;
    }

    return this.subjects[key];
  }
}
