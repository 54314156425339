import { Component, OnInit, Inject, Input } from '@angular/core';
import * as moment from 'moment';
import { MatTableDataSource } from '@angular/material/table';
import { Transaction } from 'src/app/transactions/models/transaction';
import { ClaimsItems } from 'src/app/claims/claims';

@Component({
  selector: 'app-loe-viewer',
  templateUrl: './loe-viewer.component.html',
  styleUrls: ['./loe-viewer.component.scss']
})
export class LoeViewerComponent implements OnInit {
  @Input() record: any;
  loe: any;
  memberDetail: any;
  claims: any[];
  constructor() { }

  ngOnInit(): void {
    this.loe = this.record.loe;
    this.memberDetail = this.record.memberDetail;
    this.claims = this.record.claims;
  }
}
