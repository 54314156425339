import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppSecuredComponent } from './app-secured/app-secured.component';
import { LoginComponent } from './login/login.component';
import { AuthGuard } from './core/guards/auth-guard.service';
import { DashboardComponent } from './dashboard/dashboard.component';
import { NgxPermissionsGuard } from 'ngx-permissions';
import { AccountSettingsComponent } from './account-settings/account-settings.component';

const routes: Routes = [
  {
    path: '',
    component: AppSecuredComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
      {
        path: 'dashboard',
        component: DashboardComponent,
      },
      {
        path: 'transactions',
        loadChildren: () => import('./transactions/transactions.module').then((m) => m.TransactionsModule),
      },
      {
        path: 'product-management',
        loadChildren: () =>
          import('./product-management/product-management.module').then((m) => m.ProductManagementModule),
        canActivate: [NgxPermissionsGuard],
        data: {
          permissions: {
            only: 'PR_MGMT',
            redirectTo: '/dashboard',
          },
        },
      },
      {
        path: 'members',
        loadChildren: () => import('./members/members.module').then((m) => m.MembersModule),
        canActivate: [NgxPermissionsGuard],
        data: {
          permissions: {
            only: 'MM_MGMT',
            redirectTo: '/dashboard',
          },
        },
      },
      {
        path: 'companies',
        loadChildren: () => import('./companies/companies.module').then((m) => m.CompaniesModule),
        canActivate: [NgxPermissionsGuard],
        data: {
          permissions: {
            only: 'CO_MGMT',
            redirectTo: '/dashboard',
          },
        },
      },
      {
        path: 'claims',
        loadChildren: () => import('./claims/claims.module').then((m) => m.ClaimsModule),
        canActivate: [NgxPermissionsGuard],
        data: {
          permissions: {
            only: 'CL_MOD',
            redirectTo: '/dashboard',
          },
        },
      },
      {
        path: 'contract-management',
        loadChildren: () =>
          import('./contract-management/contract-management.module').then((m) => m.ContractManagementModule),
        canActivate: [NgxPermissionsGuard],
        data: {
          permissions: {
            only: 'PC_MGMT',
            redirectTo: '/dashboard',
          },
        },
      },
      {
        path: 'medical-providers',
        loadChildren: () =>
          import('./medical-providers/medical-providers.module').then((m) => m.MedicalProvidersModule),
        canActivate: [NgxPermissionsGuard],
        data: {
          permissions: {
            only: 'MP_MGMT',
            redirectTo: '/dashboard',
          },
        },
      },
      {
        path: 'user-management',
        loadChildren: () => import('./user-management/user-management.module').then((m) => m.UserManagementModule),
        canActivate: [NgxPermissionsGuard],
        data: {
          permissions: {
            only: 'US_MGMT',
            redirectTo: '/dashboard',
          },
        },
      },
      {
        path: 'business-rules',
        loadChildren: () =>
          import('./business-rules-management/business-rules-management.module').then(
            (m) => m.BusinessRulesManagementModule
          ),
        canActivate: [NgxPermissionsGuard],
        data: {
          permissions: {
            only: 'BR_MGMT',
            redirectTo: '/dashboard',
          },
        },
      },
      {
        path: 'business-rules-templates',
        loadChildren: () =>
          import('./business-rules-template/business-rules-template.module').then((m) => m.BusinessRulesTemplateModule),
        canActivate: [NgxPermissionsGuard],
        data: {
          permissions: {
            only: 'BRT_MGMT',
            redirectTo: '/dashboard',
          },
        },
      },
      {
        path: 'find-doctors',
        loadChildren: () => import('./doctors/doctors.module').then((m) => m.DoctorsModule),
        canActivate: [NgxPermissionsGuard],
        data: {
          permissions: 'MP_MGMT_FD',
          redirectTo: '/dashboard',
        },
      },
      {
        path: 'product-packages',
        loadChildren: () =>
          import('./product-package-management/product-package-management.module').then(
            (m) => m.ProductPackageManagementModule
          ),
        canActivate: [NgxPermissionsGuard],
        data: {
          permissions: 'PP_MGMT',
          redirectTo: '/dashboard',
        },
      },
      {
        path: 'hmo-provider',
        loadChildren: () => import('./hmo-provider/hmo-provider.module').then((m) => m.HmoProviderModule),
        canActivate: [NgxPermissionsGuard],
        data: {
          permissions: {
            only: 'HMO_PRO',
            redirectTo: '/dashboard',
          },
        },
      },
      {
        path: 'hmo-provider-users',
        loadChildren: () =>
          import('./hmo-provider-users/hmo-provider-users.module').then((m) => m.HmoProviderUsersModule),
        canActivate: [NgxPermissionsGuard],
        data: {
          permissions: {
            only: 'HMO_PRO_USER',
            redirectTo: '/dashboard',
          },
        },
      },
      {
        path: 'physician-calendar',
        loadChildren: () =>
          import('./physician-calendar/physician-calendar.module').then((m) => m.PhysicianCalendarModule),
        canActivate: [NgxPermissionsGuard],
        data: {
          permissions: {
            only: 'CAL',
            redirectTo: '/dashboard',
          },
        },
      },
      {
        path: 'patients',
        loadChildren: () => import('./patients/patients.module').then((m) => m.PatientsModule),
        canActivate: [NgxPermissionsGuard],
        data: {
          permissions: 'PT_LIST',
          redirectTo: '/dashboard',
        },
      },
      {
        path: 'disease-management',
        loadChildren: () =>
          import('./disease-type-management/disease-type-management.module').then((m) => m.DiseaseTypeManagementModule),
        canActivate: [NgxPermissionsGuard],
        data: {
          permissions: 'DT_MGMT',
          redirectTo: '/dashboard',
        },
      },
      {
        // Add permissions
        path: 'reporting',
        loadChildren: () => import('./reporting/reporting.module').then((m) => m.ReportingModule),
        // canActivate: [NgxPermissionsGuard],
        // data: {
        //   permissions: 'PT_LIST',
        //   redirectTo: '/dashboard',
        // },
      },
      {
        path: 'settings',
        component: AccountSettingsComponent,
      },
    ],
  },
  {
    path: 'login',
    component: LoginComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
