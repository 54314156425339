import { Injectable, ElementRef } from '@angular/core';
import { flatten, isNil, map, assign } from 'lodash';
import Chart from 'chart.js';
import { setDecimalPlaces } from '../utility';

@Injectable({
  providedIn: 'root'
})
export class BarChartService {

  draw(
    chartAreaRef: ElementRef<any>,
    labels: any[],
    yAxes: any[] = [],
    dataSets: any,
    stacked = false,
  ) {

    const values = setDecimalPlaces(flatten(dataSets.map(x => x.data)));
    const decimal = values.decimal + 1;
    dataSets.map(x => x.data = x.data.map(y => isNil(y) ? 0 : Number(y.toFixed(decimal))));

    const chart = new Chart(chartAreaRef.nativeElement, {
      type: 'horizontalBar',
      data: {
        labels,
        datasets: dataSets
      },
      options: {
        legend: {
          display: true,
        },
        layout: {
          padding: {
            top: 0,
            left: 20,
            right: 20,
            bottom: 10
          }
        },
        tooltips: {
          mode: 'label',
          callbacks: {
          }
        },
        scales: {
          yAxes: map(yAxes, x => assign(x, {
            stacked,
            ticks: assign(x.ticks, {
              beginAtZero: true,
              stepSize: values.precision
            }),
          })),
          xAxes: [{
            gridLines: {
              display: false
            },
            barPercentage: stacked ? 0.5 : 0.25,
            stacked,
          }]
        },
        hover: {
          onHover(e) {
            const point = this.getElementAtEvent(e);
            if (point.length) {
              e.target.style.cursor = 'pointer';
            } else {
              e.target.style.cursor = 'default';
            }
          }
        }
      }
    });

    return chart;
  }

  defaultLabelStyle(label: string = '', stylize?: (label) => {}) {
    return [
      {
        id: 0,
        position: 'left',
        ticks: {
          beginAtZero: true,
          callback: stylize
        },
        gridLines: {
          lineWidth: 0.3
        },
        scaleLabel: {
          display: true,
          labelString: label
        }
      }
    ];
  }
}
