import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { AuthService } from 'src/app/core/services/auth.service';
import { ImageCroppedEvent } from 'ngx-image-cropper';

@Component({
  selector: 'app-upload-image',
  templateUrl: './upload-image.component.html',
  styleUrls: ['./upload-image.component.scss'],
})
export class UploadImageComponent implements OnInit, OnChanges {
  // Utilizes Image Cropper for Angular: https://www.npmjs.com/package/ngx-image-cropper

  @Input() readOnly = false;
  @Input() type = '';
  @Input() category = 'user';
  @Input() browseText: 'Browse Image files';
  @Input() defaultThumbnail: '';
  @Input() maxFileSize = 5; // 5MB
  @Input() enableCrop = false;
  @Input() userId = '';
  @Input() isDirectUpload = true;
  @Output() selectedImageFile = new EventEmitter<File>();
  @Output() imageUploaded = new EventEmitter<string>();
  @Output() resetUploaded = new EventEmitter<boolean>(false);

  hasFile = false;
  validImageFiles = 'jpg,jpeg,png,gif';
  imageFile: File;
  showCropDialog = false;
  imageChangedEvent: any = '';
  croppedImage: any = '';
  thumbnailImg: any;
  error = '';

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    const thumbnailChanges = changes.defaultThumbnail;
    if (thumbnailChanges && !this.hasFile) {
      this.thumbnailImg = thumbnailChanges.currentValue;
    }
  }

  constructor(private authService: AuthService) {}

  resetImage() {
    this.error = '';
    this.thumbnailImg = this.defaultThumbnail;
    this.imageFile = null;
    this.croppedImage = this.hasFile = false;
    this.imageChangedEvent = null;
    this.resetUploaded.emit(true);
  }

  removeImage() {
    this.error = '';
    this.imageFile = null;
    this.thumbnailImg = null;
    this.croppedImage = this.hasFile = false;
    this.imageChangedEvent = null;
    this.defaultThumbnail = '';

    if (this.isDirectUpload) {
      this.imageUploaded.emit('');
    } else {
      this.selectedImageFile.emit(null);
    }
  }

  onFileChange(event) {
    if (event.target.files.length) {
      this.validateImage(event.target.files[0]);
      this.imageChangedEvent = event;
    }
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }
  imageLoaded() {
    this.showCropDialog = true;
    // show cropper
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }

  async uploadCroppedImage() {
    this.showCropDialog = false;
    this.thumbnailImg = this.croppedImage;
    const res: Response = await fetch(this.croppedImage);
    const blob: Blob = await res.blob();
    const croppedImageFile = new File([blob], this.imageFile.name, { type: this.imageFile.type });

    this.uploadImage(croppedImageFile);
  }

  private validateImage(imageFile: File) {
    this.error = '';
    const isValid = this.isValidImageFileType(imageFile) && this.isValidFileSize(imageFile);

    if (isValid) {
      this.hasFile = true;

      if (this.enableCrop) {
        this.imageFile = imageFile;
      } else {
        this.uploadImage(imageFile);
      }
    }
  }

  private uploadImage(imageFile: File) {
    if (this.isDirectUpload) {
      this.authService.uploadImage(this.userId, imageFile, this.category).subscribe(
        (result) => {
          if (result.record) {
            this.imageUploaded.emit(result.record.url);
          }
        },
        (error) => {
          this.resetImage();
          this.error = error.message;
          console.log(this.error);
        }
      );
    } else {
      this.selectedImageFile.emit(imageFile);
    }
  }

  private isValidImageFileType(file: File): boolean {
    const fileExt = file.name.split('.').pop();
    return fileExt && this.validImageFiles.split(',').some((_) => _ === fileExt.toLowerCase());
  }

  private isValidFileSize(file): boolean {
    const fileSizeinMB = file.size / (1024 * 1000);
    const size = Math.round(fileSizeinMB * 100) / 100; // convert upto 2 decimal place
    return size <= this.maxFileSize;
  }
}
