import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-app-secured',
  templateUrl: './app-secured.component.html',
  styleUrls: ['./app-secured.component.scss']
})
export class AppSecuredComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }


  onToggled(isOpen: boolean) {
    if (isOpen) {
      document.getElementById('sidebar').style.width = '260px';
    } else {
      document.getElementById('sidebar').style.width = '59px';
    }
  }

}
