import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ApiInterceptorService } from './helpers/api-interceptor.service';
import { LoaderService } from './services/loader.service';
import { HttpCachingInterceptor } from './helpers/http-caching-interceptor.service';
import { MessageService } from 'primeng/api';
import { NgxPermissionsModule } from 'ngx-permissions';

@NgModule({
  imports: [
    CommonModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: ApiInterceptorService, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpCachingInterceptor, multi: true },
    LoaderService,
    MessageService
  ],
  exports: [
    NgxPermissionsModule
  ]
})
export class CoreModule { }
