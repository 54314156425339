import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { AuthService } from '../core/services/auth.service';

@Component({
  selector: 'app-account-settings',
  templateUrl: './account-settings.component.html',
  styleUrls: ['./account-settings.component.scss'],
})
export class AccountSettingsComponent implements OnInit {
  changePasswordForm: FormGroup;
  profilePhoto: string;
  user: any;

  constructor(private fb: FormBuilder, private messageService: MessageService, private authService: AuthService) {}

  ngOnInit() {
    this.initChangePasswordForm();
    this.user = this.authService.getCurrentUser();
    this.authService.profilePhoto$.subscribe((imageUrl) => (this.profilePhoto = imageUrl));
  }

  initChangePasswordForm() {
    this.changePasswordForm = this.fb.group({
      currentPassword: new FormControl('', [Validators.required]),
      newPassword: new FormControl('', [Validators.required]),
      retypePassword: new FormControl('', [Validators.required]),
    });
  }

  changePassword() {
    const currentPassword = this.changePasswordForm.get('currentPassword').value;
    const newPassword = this.changePasswordForm.get('newPassword').value;
    const retypePassword = this.changePasswordForm.get('retypePassword').value;
    if (newPassword === retypePassword) {
      this.authService.changePassword(currentPassword, newPassword).subscribe(
        (result) => {
          this.messageService.add({
            severity: 'success',
            summary: 'Password changed',
            detail: 'You may now login using your new password.',
          });
          this.clearPasswordFields();
        },
        (error) => {
          this.messageService.add({
            severity: 'error',
            summary: 'Password change failed',
            detail: error.error,
          });
        }
      );
    } else {
      this.messageService.add({
        severity: 'error',
        summary: 'Password mismatch',
        detail: 'Re-typed password does not match the new password',
      });
    }
  }

  clearPasswordFields() {
    this.changePasswordForm.reset('');
  }
}
