import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap, retry, finalize } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import { LoaderService } from '../services/loader.service';
import { MessagingService } from '../services/messaging.service';
import { isNil } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class ApiInterceptorService implements HttpInterceptor {
  constructor(
    public router: Router,
    public loaderService: LoaderService,
    private messagingService: MessagingService) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.loaderService.show();
    const baseUrl = environment.apiUrl;
    // add authorization header with jwt token if available
    // let currentUser = JSON.parse(localStorage.getItem('currentUser')); <-- should be used when user service is available
    const token = localStorage.getItem('token');
    // if (currentUser && currentUser.token) {
    if (token) {
      request = request.clone({
        url: `${baseUrl}${request.url}`,
        setHeaders: {
          'x-api-token': token
        }
      });
    } else {
      request = request.clone({
        url: `${baseUrl}${request.url}`
      });
    }

    return next.handle(request).pipe(
      tap(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            // do stuff with response if you want
          }
        },
        (err: any) => {
          if (err instanceof HttpErrorResponse) {
            if (err.status === 401) {
              this.router.navigate(['login']);
            } else if (err.status === 400 && !isNil(err.error)) {
              this.messagingService.warningMessage(err.error);
            } else if (err.status === 500) {
              this.messagingService.errorMessage(
                'System error has occured. Please try again. If this error continues, please contact your system administrator.');
              retry();
            }
          }
        }
      ),
      finalize(() => this.loaderService.hide())
    );
  }
}
