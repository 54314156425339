import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  @Output() toggled = new EventEmitter<boolean>();
  isToggleOpen: boolean;
  menuList: any[];
  selectedItem: any;
  permissions: any;
  constructor() {}

  ngOnInit() {
    this.isToggleOpen = true;
    this.menuList = [];
    this.menuList.push({
      icon: 'fas fa-chart-line',
      label: 'Dashboard',
      url: '/dashboard',
    });

    this.menuList.push({
      icon: 'fas fa-capsules',
      label: 'Products',
      url: '/product-management',
      permission: 'PR_MGMT_MAIN',
    });
    this.menuList.push({
      icon: 'fas fa-building',
      label: 'Companies',
      url: '/companies',
      permission: 'CO_MGMT',
    });
    this.menuList.push({
      icon: 'fas fa-users',
      label: 'Members',
      url: '/members',
      permission: 'MM_MGMT',
    });
    this.menuList.push({
      icon: 'fas fa-file-contract',
      label: 'Contracts',
      url: '/contract-management',
      permission: 'PC_MGMT',
    });
    this.menuList.push({
      icon: 'fas fa-clinic-medical',
      label: 'Health Care Institutions',
      url: '/medical-providers',
      permission: 'MP_MGMT',
    });
    this.menuList.push({
      icon: 'fas fa-building',
      label: 'HMO Provider',
      url: '/hmo-provider',
      permission: 'HMO_PRO',
    });
    this.menuList.push({
      icon: 'fas fa-users',
      label: 'HMO Provider Users',
      url: '/hmo-provider-users',
      permission: 'HMO_PRO_USER',
    });
    this.menuList.push({
      icon: 'fas fa-calendar-alt',
      label: 'Physicians Calendar',
      url: '/physician-calendar',
      permission: 'CAL',
    });
    this.menuList.push({
      icon: 'fas fa-users-cog',
      label: 'Users',
      url: '/user-management',
      permission: 'US_MGMT',
    });
    this.menuList.push({
      icon: 'fas fa-sitemap',
      label: 'Roles',
      url: '/user-management/roles',
      permission: 'RL_MGMT',
    });

    this.menuList.push({
      icon: 'fas fa-plus',
      label: 'Transactions',
      url: '/transactions',
      permission: 'TR_MOD',
    });
    this.menuList.push({
      icon: 'fas fa-th-list',
      label: 'Claims',
      url: '/claims',
      permission: 'CL_MOD',
    });
    this.menuList.push({
      icon: 'fas fa-cogs',
      label: 'Business Rules Assignment',
      url: '/business-rules',
      permission: 'BR_MGMT',
    });
    this.menuList.push({
      icon: 'fas fa-cubes',
      label: 'Business Rules Templates',
      url: '/business-rules-templates',
      permission: 'BRT_MGMT',
    });
    this.menuList.push({
      icon: 'fas fa-search',
      label: 'Find a Doctor',
      url: '/find-doctors',
      permission: 'MP_MGMT_FD',
    });
    this.menuList.push({
      icon: 'fas fa-receipt',
      label: 'Product Packages',
      url: '/product-packages',
      permission: 'PP_MGMT',
    });
    this.menuList.push({
      icon: 'fas fa-user',
      label: 'Patients',
      url: '/patients',
      permission: 'PT_LIST',
    });
    this.menuList.push({
      icon: 'fas fa-capsules',
      label: 'Product Management',
      url: '/product-management',
      permission: 'PR_MGMT',
    });
    this.menuList.push({
      icon: 'fas fa-medkit',
      label: 'Disease Management',
      url: '/disease-management',
      permission: 'DT_MGMT',
    });
    this.menuList.push({
      icon: 'fas fa-medkit',
      label: 'Reporting',
      url: '/reporting',
      permission: 'DT_MGMT',
    });
  }

  onSelect(item: any) {
    this.selectedItem = item;
  }

  toggleSidebar() {
    this.isToggleOpen = !this.isToggleOpen;
    this.toggled.emit(this.isToggleOpen);
  }
}
