export const AURORA_DATE_FORMAT = {
  parse: {
    dateInput: 'LL'
  },
  display: {
    dateInput: 'MM/DD/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMM YYYY'
  }
};

export enum RoleEnum {
  hmoStaff = 'HMO Staff',
  medOrgStaff = 'Medical Organization Staff',
  physician = 'Physician',
  member = 'Member'
}

export const regEx = {
  tin: /^(?:\d{3}-\d{2}-\d{4})/
};


