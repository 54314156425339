import { Component } from '@angular/core';

import 'globalize/number';
import 'globalize/date';
import 'globalize/plural';

const Globalize = require('globalize');

Globalize.load(
  require('cldr-data/supplemental/numberingSystems.json'),
  require('cldr-data/supplemental/likelySubtags.json'),
  require('cldr-data/main/en/numbers.json'),
  require('cldr-data/main/en/ca-gregorian.json'),
  require('cldr-data/main/en/timeZoneNames.json'),
  require('cldr-data/supplemental/timeData.json'),
  require('cldr-data/supplemental/weekData.json')
);

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Aurora-Web';

  constructor() {
    Globalize.locale('en');
  }
}
