import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Access } from '../models/access';
import { GetUserPermissions } from 'src/app/user-management/users';
import { lowerCase } from 'lodash';
import { RoleEnum } from '../constants';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private profilePhotoSource = new BehaviorSubject<string>('');
  profilePhoto$ = this.profilePhotoSource.asObservable();

  constructor(private http: HttpClient) {}

  public isAuthenticated(): boolean {
    const token = localStorage.getItem('token');
    // Check whether the token is expired and return
    // true or false

    if (token == null) {
      return false;
    } else {
      return true;
    }
  }

  login(userId: string, password: string): Observable<Access> {
    return this.createToken(userId, password).pipe(
      tap((result) => {
        this.setSession(result);
      })
    );
  }

  getUserPermissions(userId): Observable<GetUserPermissions> {
    return this.http.get<GetUserPermissions>(`user/permissions?userId=${userId}`);
  }

  createToken(userId: string, password: string) {
    const url = this.constructUrl('createtoken');
    return this.http.post<Access>(url, { userId, password });
  }

  private setSession(authResult) {
    localStorage.setItem('token', authResult.token);
  }

  logout() {
    localStorage.clear();
  }

  getSecurityQuestion(): Observable<Access> {
    const url = this.constructUrl('getquestion');
    return this.http.get<Access>(url);
  }

  verifySecurityAnswer(answer: string): Observable<Access> {
    const url = this.constructUrl('verifyanswer');
    return this.http.post<Access>(url, { answer });
  }

  forgotPassword(userId: string): Observable<Access> {
    const url = this.constructUrl('forgotpassword');
    return this.http.post<Access>(url, { userId });
  }

  changePassword(currentPassword: string, newPassword: string): Observable<any> {
    const url = this.constructUrl('changepassword');
    return this.http.post<Access>(url, {
      currentPassword,
      newPassword,
    });
  }

  changePin(pin: string): Observable<Access> {
    const url = this.constructUrl('savepin');
    return this.http.post<Access>(url, { pin });
  }

  setupSecurityQuestion(question: string, answer: string): Observable<Access> {
    const url = this.constructUrl('setupsecurityquestion');
    return this.http.post<Access>(url, { question, answer });
  }

  getUserInfo() {
    const url = this.constructUrl('getuserinfo');
    return this.http.get<any>(url).pipe(
      tap((userInfo) => {
        localStorage.setItem('currentUser', JSON.stringify(userInfo.record));
        this.updateProfilePhoto(userInfo.record.imageUrl);
      })
    );
  }

  uploadImage(userId: string, imageFile: File, category: string = 'user') {
    const url = this.constructUrl('uploadimage');

    const formData = new FormData();
    formData.append('file', imageFile);
    formData.append('category', category);
    formData.append('userid', userId);

    return this.http.post<any>(url, formData);
  }

  private updateProfilePhoto(imageUrl: string) {
    this.profilePhotoSource.next(imageUrl);
  }

  getCurrentUserRole() {
    return JSON.parse(localStorage.getItem('currentUser')).role;
  }

  getCurrentUser() {
    return JSON.parse(localStorage.getItem('currentUser'));
  }

  isLoggedInUserPhysician() {
    return lowerCase(this.getCurrentUserRole()) === lowerCase(RoleEnum.physician);
  }

  private constructUrl(accessPoint: string): string {
    return `access/${accessPoint}`;
  }
}
