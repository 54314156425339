import { Pipe, PipeTransform } from '@angular/core';
const Globalize = require('globalize');

@Pipe({
  name: 'localNumber'
})
export class LocalNumberPipe implements PipeTransform {

  formatter = Globalize.numberFormatter({ maximumFractionDigits: 2, minimumFractionDigits: 2 });
  constructor() { }

  transform(value: any) {
    if (value == null) { return ''; }

    return this.formatter(value);
  }

}
